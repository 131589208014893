import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { constantUtils } from '../helpers/constants';
import { BaseService } from './base.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  
  constructor(
    private baseService: BaseService,
    private authService: AuthService) {

  }

  get(api: string, params?: any): Observable<any> {
    if(!this.authService.isTokenAlive()) {

    }
    return this.baseService.get(api, params);
  }

  post(api: string, formData: any): Observable<any> {
    if(!this.authService.isTokenAlive()) {
      
    }
    return this.baseService.post(api, formData);
  }

  patch(api: string, formData: any): Observable<any> {
    if(!this.authService.isTokenAlive()) {
      
    }
    return this.baseService.patch(api, formData);
  }

  delete(api: string, params?: any): Observable<any> {
    if(!this.authService.isTokenAlive()) {

    }
    return this.baseService.delete(api, params);
  }

  async postUploadFiles(api: string, file: File) {
    if(!this.authService.isTokenAlive()) {
      
    }
    return await this.baseService.postUploadFiles(api, file);
  }
}
