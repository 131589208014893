import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { notificationTypes } from '../../helpers/constants';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  constructor(private toastr: ToastrService) { 

  }

  notificationMessage(_type, _message, _title) {
    if(_type == notificationTypes.SUCCESS) {
      this.toastr.success(_message, _title)  
    }
    else if(_type == notificationTypes.ERROR) {
      this.toastr.error(_message, _title)  
    }
    else if(_type == notificationTypes.INFO) {
      this.toastr.info(_message, _title)  
    }
    else if(_type == notificationTypes.WARNING) {
      this.toastr.warning(_message, _title)  
    }
  }

  notificationErrorMessage(error) {
    if(error.error.message) {
      this.notificationMessage(`${notificationTypes.ERROR}`, error.error.message, '');
    }
    else if(error.message) {
      this.notificationMessage(`${notificationTypes.ERROR}`, error.message, '');
    }
  }
}
