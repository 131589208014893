<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">     
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink='/'>
              <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
            </a>
          </div>
          <div class="login-main"> 
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Forgot your Password?</h4>
              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input class="form-control" type="email" placeholder="Email Address" formControlName="email" 
                  [ngClass]="{'is-invalid': ((loginForm.controls.email.touched && loginForm.controls.email.errors?.required) ||
                    (loginForm.controls.email.touched && loginForm.controls.email.errors?.email))}"
                  required />
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                    class="text text-danger mt-1">
                    Email is required
                </div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                    class="text text-danger mt-1">
                    Invalid Email
                </div>
              </div>
              <div class="form-group mb-0">
                <button class="btn btn-primary d-block w-100"
                  [class.loader--text]="showLoader"
                  [disabled]="!loginForm.valid || showLoader"
                  (click)="validateEmail()"
                  type="button"><span>{{ showLoader ? '' : 'Reset Password' }}</span>
                </button>
              </div>
              <div class="form-group mt-2 text-center">
                <a [routerLink]="'/auth/login'">Click here to Login</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>