import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { constantUtils } from '../helpers/constants';
import { BaseService } from './base.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

export interface User {
  userID: string;
  email: string;
  userName: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUser: Observable<any>;

  private tokenKey = `ra1_token`;
  private tokenExpiryKey = `ra1_tokenExpiry`;
  private refreshTokenKey = `ra1_refreshToken`;
  private userIDKey = `ra1_userID`;
  private userNameKey = `ra1_userName`;
  private emailKey = `ra1_email`;
  private customerIDKey = `ra1_roleID`;
  private roleIDKey = `ra1_roleID`;
  private branchIDKey = `ra1_branchID`;
  private designationIDKey = `ra1_designationID`;
  private featureAccessKey = `ra1_featureAccess`;

  constructor(private myRoute: Router, 
    private httpClient: HttpClient, 
    private router: Router, 
    private baseService: BaseService,
    public afs: AngularFirestore, // Inject Firestore service
    ) {

  }

  login(email: string, password: string): Observable<any> {
    var formData = {
      email: email,
      password: password
    };

    return this.baseService.post(`${constantUtils.AUTH_URL}`, formData);
  }
  logout() {
    this.baseService.post(`${constantUtils.LOGOUT_URL}`, {}).subscribe(response => {
      console.log(response);
      debugger;
      localStorage.removeItem(btoa(this.tokenKey));
      localStorage.removeItem(btoa(this.tokenExpiryKey));
      localStorage.removeItem(btoa(this.refreshTokenKey));
      localStorage.removeItem(btoa(this.userIDKey));
      localStorage.removeItem(btoa(this.userNameKey));
      localStorage.removeItem(btoa(this.emailKey));
      localStorage.removeItem(btoa(this.customerIDKey));
      localStorage.removeItem(btoa(this.roleIDKey));
      localStorage.removeItem(btoa(this.branchIDKey));
      localStorage.removeItem(btoa(this.designationIDKey));
      localStorage.removeItem(btoa(this.featureAccessKey));

      this.stopRefreshTokenTimer();

      // Redirect to Login Page
      this.router.navigate(["auth/login"]);
    }, error => {
      console.log(error);
    });
  }

  refreshToken(token, refreshToken): Observable<any> {
    var formData = {
      authToken: token,
      refreshToken: refreshToken
    }
    return this.baseService.post(`${constantUtils.REFRESH_TOKEN_URL}`, formData);
  }

  forgotPassword(email: string): Observable<any> {
    var formData = {
      email: email
    };

    return this.baseService.post(`${constantUtils.FORGOT_PASSWORD_URL}`, formData);
  }

  resetPassword(email: string, password: string): Observable<any> {
    var formData = {
      email: email,
      password: password
    };

    return this.baseService.post(`${constantUtils.RESET_PASSWORD_URL}`, formData);
  }

  // User Data
  setToken(token: string, expiryMinutes: any, refreshToken: string) {
    var currentDate = new Date();
    var expiry = new Date(currentDate.getTime() + expiryMinutes * 60000);

    localStorage.setItem(btoa(this.tokenKey), btoa(token));
    localStorage.setItem(btoa(this.tokenExpiryKey), btoa(expiry.toString()));
    localStorage.setItem(btoa(this.refreshTokenKey), btoa(refreshToken));

    this.startRefreshTokenTimer(token, expiry, refreshToken);
  }
  isTokenAlive() {
    let expiry = localStorage.getItem(btoa(this.tokenExpiryKey));
    expiry = atob(expiry);
    let expiryTimes = new Date(expiry);
    if (Date.now() <= expiryTimes.getTime()) {
      return true;
    } else {
      return false;
    }
  }
  getToken() {
    let token = localStorage.getItem(btoa(this.tokenKey));
    token = token === null ? token : atob(token);

    if (token) {
      if (this.isTokenAlive()) {
        
      }
    }

    return this.isTokenAlive() ? token : null;
  }
  isLoggednIn() {
    return this.getToken() !== null;
  }

  private refreshTokenTimeout;
  private startRefreshTokenTimer(token, expiry, refreshToken) {
    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(expiry.getTime() - (2 * 60000));
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => {
      this.refreshToken(token, refreshToken).subscribe(response => {
        if(response.data != false) {
          var data = response.data;
          this.setToken(data.authToken, data.authTokenExpire, data.refreshToken);
          this.setUserID(data.userId);
          this.setUserName(data.fullName);
          this.setEmail(data.email);
          if(data.customerId == null)
            data.customerId = 0;
          this.setCustomerID(data.customerId);
          if(data.roleId == null)
            data.roleId = 0;
          this.setRoleID(data.roleId);
          if(data.branchId == null)
            data.branchId = 0;
          this.setBranchID(data.branchId);
        }
      }, error => {
        //this.errorMessage = error.error.error_description;
      })
    }, timeout);
  }
  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  setUserID(userID: string) {
    localStorage.setItem(btoa(this.userIDKey), btoa(userID));
  }
  getUserID() {
    let userID = localStorage.getItem(btoa(this.userIDKey));
    userID = userID === null ? userID : atob(userID);
    return userID;
  }

  setUserName(userName: string) {
    localStorage.setItem(btoa(this.userNameKey), btoa(userName));
  }
  getUserName() {
    let userName = localStorage.getItem(btoa(this.userNameKey));
    userName = userName === null ? userName : atob(userName);
    return userName;
  }

  setEmail(email: string) {
    localStorage.setItem(btoa(this.emailKey), btoa(email));
  }
  getEmail() {
    let email = localStorage.getItem(btoa(this.emailKey));
    email = email === null ? email : atob(email);
    return email;
  }

  setCustomerID(userID: string) {
    localStorage.setItem(btoa(this.customerIDKey), btoa(userID));
  }
  getCustomerID() {
    let customerID = localStorage.getItem(btoa(this.customerIDKey));
    customerID = customerID === null ? customerID : atob(customerID);
    return customerID;
  }

  setRoleID(userID: string) {
    localStorage.setItem(btoa(this.roleIDKey), btoa(userID));
  }
  getRoleID() {
    let roleID = localStorage.getItem(btoa(this.roleIDKey));
    roleID = roleID === null ? roleID : atob(roleID);
    return roleID;
  }

  setBranchID(userID: string) {
    localStorage.setItem(btoa(this.branchIDKey), btoa(userID));
  }
  getBranchID() {
    let branchID = localStorage.getItem(btoa(this.branchIDKey));
    branchID = branchID === null ? branchID : atob(branchID);
    return branchID;
  }

  setDesignationID(designationID: string) {
    localStorage.setItem(btoa(this.designationIDKey), btoa(designationID));
  }
  getDesignationID() {
    let designationID = localStorage.getItem(btoa(this.designationIDKey));
    designationID = designationID === null ? designationID : atob(designationID);
    return designationID;
  }

  setPageFeatureAccess(featuresList: any) {
    let _featuresJson = JSON.stringify(featuresList);
    localStorage.setItem(btoa(this.featureAccessKey), btoa(_featuresJson));
  }
  getPageFeatureAccess() {
    let _featuresJsonEncryp = localStorage.getItem(btoa(this.featureAccessKey));
    if(_featuresJsonEncryp != null) {
      let _featuresJson = atob(_featuresJsonEncryp);
      return JSON.parse(_featuresJson);
    }
    return [];
  }


  register(formData: any): Observable<any> {
    return this.baseService.post(`${constantUtils.REGISTER_URL}`, formData);
  }

  // verifyEmail(token: any): Observable<any> {
  //   var _verifyURL = `${constantUtils.VERIFY_EMAIL_URL}` + '?token=' + token;
  //   return this.baseService.post(_verifyURL, null);
  // }

  // completeProfile(formData: any): Observable<any> {
  //   return this.baseService.post(`${constantUtils.COMPLETE_PROFILE_URL}`, formData);
  // }


  setUserData(user: any) {
    if (user) {
      const userData: User = {
        userID: user.userID,
        email: user.email,
        userName: user.userName
      };

      localStorage.setItem('user', JSON.stringify(userData));
      JSON.parse(localStorage.getItem('user')!);
    } else {
      localStorage.setItem('user', 'null');
      JSON.parse(localStorage.getItem('user')!);
    }

  }
}
