import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { environment } from '../environments/environment';
import { constantUtils } from '../helpers/constants';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class BaseService {
  constructor(private httpClient: HttpClient) {}

  get(api: string, params?: any) {
    const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };

    var _url = constantUtils.BASE_API_URL + api;
    if (params) {
        _url = _url + params;
    }
    return this.httpClient
        .get(_url, { headers })
        .pipe(map((response: Response) => response));
  }

  post(api: string, formData: any) {
    const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };
    var _url = constantUtils.BASE_API_URL + api;
  
    return this.httpClient
        .post(_url, formData, { headers })
        .pipe(map((response: Response) => response));
  }

  patch(api: string, formData: any) {
    const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };
    var _url = constantUtils.BASE_API_URL + api;
    return this.httpClient
        .patch(_url, formData, { headers })
        .pipe(map((response: Response) => response));
  }

  delete(api: string, params?: any) {
    const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };

    var _url = constantUtils.BASE_API_URL + api;
    if (params) {
        _url = _url + params;
    }
    return this.httpClient
        .delete(_url, { headers })
        .pipe(map((response: Response) => response));
  }

  postUploadFiles(api: string, file: File) {
    var _url = constantUtils.BASE_API_URL + api;
    let formData = new FormData();
    formData.append('file', file);

    return new Promise((resolve, reject) => {
      this.httpClient.post(_url, formData).subscribe(
        (res) => {
          resolve(res);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

}
