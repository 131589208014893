
export const constantUtils = {

    // -- For Local Host
    // BASE_API_URL: "http://localhost:8080",
    // FILE_UPLOAD_PATH_URL: "http://localhost:8080",

    // -- For Dev Test
    // BASE_API_URL: "https://devapi.vinusmultiventures.com",
    // FILE_UPLOAD_PATH_URL: "https://devapi.vinusmultiventures.com",

    // -- For Live
    BASE_API_URL: "https://api.vinusmultiventures.com",
    FILE_UPLOAD_PATH_URL: "https://api.vinusmultiventures.com",
  
    AUTH_URL: '/api/account/login',
    LOGOUT_URL: '/api/account/logout',
    REFRESH_TOKEN_URL: '/api/account/refresh-accesstoken',
    REGISTER_URL: '/api/account/register',
    FORGOT_PASSWORD_URL: '/api/account/forgot-password',
    VALIDATE_RSET_PASSWORD_URL:  '/api/account/validate-reset-password-token',
    RESET_PASSWORD_URL:  '/api/account/reset-password',

    DASHBOARD_DATA_URL: '/api/dashboard/dashbaord-data',
    
    USER_LIST_URL: '/api/user/user-list',
    USER_SAVE_URL: '/api/user/user-manage',
    USER_DETAILS_URL: '/api/user/user-details',
    USER_DELETE_URL: '/api/user/user-delete',

    DIMENSION_LIST_URL: '/api/settings/dimension/dimension-list',
    DIMENSION_SAVE_URL: '/api/settings/dimension/dimension-manage',
    DIMENSION_DETAILS_URL: '/api/settings/dimension/dimension-details',
    DIMENSION_DELETE_URL: '/api/settings/dimension/dimension-delete',
    
    TENURE_LIST_URL: '/api/settings/tenure/tenure-list',
    TENURE_SAVE_URL: '/api/settings/tenure/tenure-manage',
    TENURE_DETAILS_URL: '/api/settings/tenure/tenure-details',
    TENURE_DELETE_URL: '/api/settings/tenure/tenure-delete',

    TAX_LIST_URL: '/api/settings/tax/tax-list',
    TAX_SAVE_URL: '/api/settings/tax/tax-manage',
    TAX_DETAILS_URL: '/api/settings/tax/tax-details',

    COMMISSION_PERCENTAGE_DETAILS_URL: '/api/settings/comission-percentage/comission-percentage-details',
    COMMISSION_PERCENTAGE_SAVE_URL: '/api/settings/comission-percentage/comission-percentage-manage',

    FEATURES_LIST_URL: '/api/settings/role/role-features-list',
    ROLE_LIST_URL: '/api/settings/role/role-list',
    ROLE_SAVE_URL: '/api/settings/role/role-manage',
    ROLE_DETAILS_URL: '/api/settings/role/role-details',
    ROLE_DELETE_URL: '/api/settings/role/role-delete',

    BRANCH_LIST_URL: '/api/branch/branch-list',
    BRANCH_SAVE_URL: '/api/branch/branch-manage',
    BRANCH_DETAILS_URL: '/api/branch/branch-details',
    BRANCH_DELETE_URL: '/api/branch/branch-delete',
    
    CUSTOMER_LIST_URL: '/api/customer/customer-list',
    CUSTOMER_SAVE_URL: '/api/customer/customer-manage',
    CUSTOMER_DETAILS_URL: '/api/customer/customer-details',
    CUSTOMER_DELETE_URL: '/api/customer/customer-delete',
    
    PROJECT_LIST_URL: '/api/project/project-list',
    PROJECT_SAVE_URL: '/api/project/project-manage',
    PROJECT_DETAILS_URL: '/api/project/project-details',
    PROJECT_SCHEME_LIST_URL: '/api/project/project-scheme-list',

    CUSTOMER_PROJECT_ALLOCATION_LIST_URL: '/api/customer-project-allocation/allocation-list',
    CUSTOMER_PROJECT_ALLOCATION_SAVE_URL: '/api/customer-project-allocation/allocation-manage',
    CUSTOMER_PROJECT_ALLOCATION_DETAILS_URL: '/api/customer-project-allocation/allocation-details',
    CUSTOMER_PROJECT_ALLOCATION_CANCEL_URL: '/api/customer-project-allocation/allocation-cancel',
    
    CUSTOMER_PROJECT_PAYMENT_LIST_URL: '/api/customer-project-payment/payment-list',
    CUSTOMER_PROJECT_PAYMENT_SAVE_URL: '/api/customer-project-payment/payment-manage',
    CUSTOMER_PROJECT_PAYMENT_DETAILS_URL: '/api/customer-project-payment/payment-details',
    CUSTOMER_PROJECT_PAYMENT_APPROVAL_URL: '/api/customer-project-payment/payment-approval',

    PAYMENT_COMMISSION_LIST_URL: '/api/payment-commission/commission-list',
    PAYMENT_COMMISSION_APPROVAL_URL: '/api/payment-commission/commission-approval',

    // Other common APIs
    ROLES_SELECT_LIST_URL: '/api/settings/role/role-select-list',
    USER_SELECT_LIST_URL: '/api/user/user-select-list',
    USER_REPORTING_TO_SELECT_LIST_URL: '/api/user/user-reportingto-select-list',
    PROMOTER_SELECT_LIST_URL: '/api/user/promoter-select-list',
    DIMENSION_SELECT_LIST_URL: '/api/settings/dimension/dimension-select-list',
    TENURE_SELECT_LIST_URL: '/api/settings/tenure/tenure-select-list',
    DESIGNATION_SELECT_LIST_URL: '/api/settings/designation/designation-select-list',
    ROLE_SELECT_LIST_URL: '/api/settings/role/role-select-list',
    BRANCH_SELECT_LIST_URL: '/api/branch/branch-select-list',
    CUSTOMER_SELECT_LIST_URL: '/api/customer/customer-select-list',
    PROJECT_SELECT_LIST_URL: '/api/project/project-select-list',
    PROJECT_SCHEME_SELECT_LIST_URL: '/api/project/project-scheme-select-list',
    CUSTOMER_PROJECT_SELECT_LIST_URL: '/api/customer-project-allocation/customer-project-select-list',
    CUSTOMER_PROJECT_ALLOCATION_SELECT_LIST_URL: '/api/customer-project-allocation/customer-project-allocation-select-list',
    UPLOAD_FILE_URL: '/api/common/upload-file/',

    // PROMOTER
    PROMOTER_COMMISION_DATA_URL: '/api/promoter/commission-list',
    PROMOTER_COMMISION_DETAILS_DATA_URL: '/api/promoter/commission-details',

    // CUSTOMER
    CUSTOMER_PROJECTS_LIST_URL: '/api/customer/projects-list',
    CUSTOMER_PROJECTS_DATA_URL: '/api/customer/projects-data-list',
    CUSTOMER_PROJECTS_DETAILS_DATA_URL: '/api/customer/project-details',

  };

export const notificationTypes = {
    SUCCESS: "success",
    ERROR: "error",
    INFO: "info",
    WARNING: "warning"
};

export const bucketFolder = {
    customerLogos: "1",
    userProfiles: "2",
    tickets: "10"
};

export const workflowApprovalTypes = {
    branchPurchaseOrder: "1"
};