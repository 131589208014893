import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { constantUtils } from './../../../helpers/constants';
import { AuthService } from '../../../service/auth.service';
import { CommonService } from './../../../service/common.service';
import { ValidationService } from '../../../helpers/validation/validation.service';
import { NotificationService } from '../../../helpers/notification/notification.service';
import { notificationTypes } from '../../../helpers/constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public showLoader: boolean = false;
  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  // authService: any;

  // public authService: AuthService,
  constructor(private fb: FormBuilder,
    public router: Router, 
    public route: ActivatedRoute,
    public httpClient: HttpClient, 
    public changeDetect: ChangeDetectorRef,
    public authService: AuthService,
    public commonService: CommonService,
    public validationService: ValidationService,
    public notifyService: NotificationService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  async ngOnInit() {
    debugger;
    //Read Rest Password Token
    var token = this.route.snapshot.paramMap.get('token');
    await this.validateToken(token);
  }

  // Redirect to Login page
  redirectToLogin() {
    this.router.navigate(['auth/login']);
  }
  async validateToken(_token) {
    debugger;
    if(_token != null && _token != '') {
      var params = '?token=' + _token;
      this.showLoader = true;
      this.commonService.get(`${constantUtils.VALIDATE_RSET_PASSWORD_URL}`, params).subscribe(response => {
        debugger;
        this.showLoader = false;
        if(response) {
          this.loginForm.patchValue({
            email: response.entity.email
          });
        }
        else {
          this.notifyService.notificationMessage(`${notificationTypes.ERROR}`, 'Invalid Password link', '');
          this.redirectToLogin();
        }
      }, error => {
        this.notifyService.notificationErrorMessage(error);
        this.showLoader = false;
        this.redirectToLogin();
      });
    }
    else {
      this.notifyService.notificationMessage(`${notificationTypes.ERROR}`, 'Details not found', '');
      this.redirectToLogin();
    }
  }

  resetPassword() {
    debugger;
    let emailID = this.loginForm.value['email'];
    let password = this.loginForm.value['password'];
    let confirmPassword = this.loginForm.value['confirmPassword'];

    this.errorMessage = '';
    // Validation
    if(emailID == null || emailID == '') {
      //this.errorMessage = 'Please enter Email Address';
      this.notifyService.notificationMessage(`${notificationTypes.ERROR}`, 'Please enter Email Address', '');
      return;
    }
    if(!this.validationService.isValidEmail(emailID)) {
      //this.errorMessage = 'Please enter valid Email Address';
      this.notifyService.notificationMessage(`${notificationTypes.ERROR}`, 'Please enter valid Email Address', '');
      return;
    }
    if(password == null || password == '') {
      //this.errorMessage = 'Please enter Password';
      this.notifyService.notificationMessage(`${notificationTypes.ERROR}`, 'Please enter Password', '');
      return;
    }
    if(confirmPassword == null || confirmPassword == '') {
      //this.errorMessage = 'Please enter Confirm Password';
      this.notifyService.notificationMessage(`${notificationTypes.ERROR}`, 'Please enter Confirm Password', '');
      return;
    }

    if(password != confirmPassword) {
      //this.errorMessage = "Password and Confirm Password didn't match";
      this.notifyService.notificationMessage(`${notificationTypes.ERROR}`, "Password and Confirm Password didn't match", '');
      return;
    }
    
    this.showLoader = true;
    this.errorMessage = "";
    this.authService.resetPassword(emailID, password).subscribe(response => {
      debugger;
      console.log(response);
      this.showLoader = false;
      if(response.data != false) {
        var data = response.data;
        this.notifyService.notificationMessage(`${notificationTypes.SUCCESS}`, 'Password reset successfully', '');
        this.redirectToLogin();
      } else {
        this.showLoader = false;
        this.notifyService.notificationMessage(`${notificationTypes.ERROR}`, response.message, '');
      }
    }, error => {
      //this.errorMessage = error.error.error_description;
      //this.notifyService.notificationMessage(`${notificationTypes.ERROR}`, error.error.error_description, '');
      this.notifyService.notificationMessage(`${notificationTypes.ERROR}`, error.error.message, '');
      this.showLoader = false;
    });
  }
}
