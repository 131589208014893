import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'branch',
    loadChildren: () => import('../../components/branch/branch.module').then(m => m.BranchModule)
  },
  {
    path: 'project',
    loadChildren: () => import('../../components/project/project.module').then(m => m.ProjectModule)
  },
  {
    path: 'employees',
    loadChildren: () => import('../../components/employees/employees.module').then(m => m.EmployeesModule)
  },
  {
    path: 'roles',
    loadChildren: () => import('../../components/roles/roles.module').then(m => m.RolesModule)
  },
  {
    path: 'customer',
    loadChildren: () => import('../../components/customer/customer.module').then(m => m.CustomerModule)
  },

  {
    path: 'dimension',
    loadChildren: () => import('../../settings/dimension/dimension.module').then(m => m.DimensionModule)
  },
  {
    path: 'tenure',
    loadChildren: () => import('../../settings/tenure/tenure.module').then(m => m.TenureModule)
  },
  {
    path: 'tax',
    loadChildren: () => import('../../settings/tax/tax.module').then(m => m.TaxModule)
  },
  {
    path: 'commission',
    loadChildren: () => import('../../settings/commission/commission.module').then(m => m.CommissionModule)
  },
  {
    path: 'user-profiles',
    loadChildren: () => import('../../settings/profiles/profiles.module').then(m => m.ProfilesModule)
  },
];
