<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">     
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink='/'>
              <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
              <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
            </a>
          </div>
          <div class="login-main"> 
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Sign in to account</h4>
              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input class="form-control" type="email" placeholder="Email Address" formControlName="email" 
                  [ngClass]="{'is-invalid': ((loginForm.controls.email.touched && loginForm.controls.email.errors?.required) ||
                    (loginForm.controls.email.touched && loginForm.controls.email.errors?.email))}"
                  required />
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                    class="text text-danger mt-1">
                    Email is required
                </div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                    class="text text-danger mt-1">
                    Invalid Email
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" placeholder="*********" 
                  [ngClass]="{'is-invalid': (loginForm.controls.password.touched && loginForm.controls.password.errors?.required)}"
                  />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                      class="text text-danger mt-1">
                      Password is required
                </div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox">
                  <label class="text-muted" for="checkbox1">Remember password</label>
                </div>
                <a [routerLink]="'/auth/forgot-password'" class="link">Forgot password?</a>
                <button class="btn btn-primary d-block w-100"
                  [class.loader--text]="showLoader"
                  [disabled]="!loginForm.valid || showLoader"
                  (click)="login()"
                  type="button"><span>{{ showLoader ? '' : 'Login' }}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>