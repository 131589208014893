import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from '../../../service/auth.service';
import { ValidationService } from '../../../helpers/validation/validation.service';
import { NotificationService } from '../../../helpers/notification/notification.service';
import { notificationTypes } from '../../../helpers/constants';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public showLoader: boolean = false;
  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  // authService: any;

  // public authService: AuthService,
  constructor(private fb: FormBuilder,
    public router: Router, 
    public route: ActivatedRoute,
    public authService: AuthService,
    public validationService: ValidationService,
    public notifyService: NotificationService) {
    this.loginForm = this.fb.group({
      email: ['admin@vinus.com', [Validators.required, Validators.email]], //admin@vinus.com
      //promo1@mailinator.com
    });
  }

  ngOnInit() {
    
  }

  validateEmail() {
    let emailID = this.loginForm.value['email'];
    
    this.errorMessage = '';
    // Validation
    if(emailID == null || emailID == '') {
      this.errorMessage = 'Please enter Email Address';
      return;
    }
    if(!this.validationService.isValidEmail(emailID)) {
      this.errorMessage = 'Please enter valid Email Address';
      return;
    }
    
    this.showLoader = true;
    this.errorMessage = "";
    this.authService.forgotPassword(emailID).subscribe(response => {
      debugger;
      console.log(response);
      this.showLoader = false;
      if(response.data != false) {
        var data = response.data;
        this.notifyService.notificationMessage(`${notificationTypes.SUCCESS}`, 'Password reset link is sent to registered email', '');
        this.router.navigate(['auth/login']);

      } else {
        this.showLoader = false;
        this.notifyService.notificationMessage(`${notificationTypes.ERROR}`, response.message, '');
      }
    }, error => {
      //this.errorMessage = error.error.error_description;
      //this.notifyService.notificationMessage(`${notificationTypes.ERROR}`, error.error.error_description, '');
      this.notifyService.notificationMessage(`${notificationTypes.ERROR}`, error.error.message, '');
      this.showLoader = false;
    });
  }

}
