import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from '../../../service/auth.service';
import { ValidationService } from '../../../helpers/validation/validation.service';
import { NotificationService } from '../../../helpers/notification/notification.service';
import { notificationTypes } from '../../../helpers/constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public showLoader: boolean = false;
  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  // authService: any;

  // public authService: AuthService,
  constructor(private fb: FormBuilder,
    public router: Router, 
    public route: ActivatedRoute,
    public authService: AuthService,
    public validationService: ValidationService,
    public notifyService: NotificationService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]], //admin@vinus.com
      password: ['', Validators.required] //12345678
      //promo1@mailinator.com
    });
    // this.loginForm = this.fb.group({
    //   email: ['cust1@mailainator.com', [Validators.required, Validators.email]], //admin@vinus.com
    //   password: ['9876543210', Validators.required] //12345678
    // });

  }

  ngOnInit() {
    
  }

  showPassword() {
    this.show = !this.show;
  }

  login() {

    // this.authService.setToken('dgfgfdgdfgdfgrtbfdbghh', '5000', 'dgfgfdgdfgdfgrtbfdbghh');
    // this.authService.setUserID('1');
    // this.authService.setUserName('Admin');
    // this.authService.setEmail('admin@vinus.com');
    // this.authService.setCustomerID('0');
    // this.authService.setRoleID('1');
    // this.authService.setBranchID('0');

    // const user = {
    //   userID: 1,
    //   email: 'admin@vinus.com',
    //   userName: 'Admin'
    // };

    // this.authService.setUserData(user);
    // this.showLoader = true;
    // // Redirect to Dashboard
    // this.router.navigate(['/dashboard']);

    let emailID = this.loginForm.value['email'];
    let password = this.loginForm.value['password'];

    this.errorMessage = '';
    // Validation
    if(emailID == null || emailID == '') {
      this.errorMessage = 'Please enter Email Address';
      return;
    }
    if(!this.validationService.isValidEmail(emailID)) {
      this.errorMessage = 'Please enter valid Email Address';
      return;
    }
    if(password == null || password == '') {
      this.errorMessage = 'Please enter Password';
      return;
    }

    this.showLoader = true;
    this.errorMessage = "";
    this.authService.login(emailID, password).subscribe(response => {
      this.showLoader = false;
      if(response.data != false) {
        var data = response.data;
        //this.authService.setToken(data.authToken, data.authTokenExpire, data.refreshToken);
        this.authService.setToken(data.auth_token, 5000, data.refresh_token);
        this.authService.setUserID(data.u_id);
        this.authService.setUserName(data.user_name);
        this.authService.setEmail(data.email);
        if(data.role_id == null)
          data.role_id = 0;
        this.authService.setRoleID(data.role_id);
        if(data.branch_id == null)
          data.branch_id = 0;
        this.authService.setBranchID(data.branch_id);
        if(data.designation_id == null)
          data.designation_id = 0;
        this.authService.setDesignationID(data.designation_id);

        // Set Page Features Access Data
        this.authService.setPageFeatureAccess(data.feature_list);

        const user = {
          userID: data.u_id,
          email: data.email,
          userName: data.user_name
        };

        this.authService.setUserData(user);

        // Redirect to Dashboard
        this.router.navigate(["/dashboard"]);
      } else {
        this.showLoader = false;
        this.notifyService.notificationMessage(`${notificationTypes.ERROR}`, response.message, '');
      }
    }, error => {
      //this.errorMessage = error.error.error_description;
      //this.notifyService.notificationMessage(`${notificationTypes.ERROR}`, error.error.error_description, '');
      this.notifyService.notificationMessage(`${notificationTypes.ERROR}`, error.error.message, '');
      this.showLoader = false;
    });
  }

}
