import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { 

  }

  isValidEmail(text:string):boolean {
    var  isValid:boolean;
    var regexp = new RegExp(/^[a-zA-Z][a-zA-Z0-9_.%+-]+@[a-zA-Z0-9._%+-][a-zA-Z0-9._-]+\.[a-zA-Z]{2,4}$/);
    isValid = regexp.test(text);
    return isValid;
  }

  isValidPassword(text:string):boolean {
    var  isValid:boolean;
    var regexp = new RegExp(/^[a-zA-Z][a-zA-Z0-9_.%+-]+@[a-zA-Z0-9._%+-][a-zA-Z0-9._-]+\.[a-zA-Z]{2,4}$/);
    isValid = regexp.test(text);
    return isValid;
  }

  isValidPhone(text:string):boolean {
    var  isValid:boolean;
    var regexp = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/);
    isValid = regexp.test(text);
    return isValid;
  }

  isValidMobile(text:string):boolean {
    var  isValid:boolean;
    var regexp = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/);
    isValid = regexp.test(text);
    return isValid;
  }
}
