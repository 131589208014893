<div class="media profile-media">
  <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="">
  <div class="media-body">
    <span>{{ userName }} </span>
    <span class="mb-0 font-roboto"><i class="middle fa fa-angle-down"></i></span>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li><a href="/user/profile"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Account </span></a></li>
  <li (click)="logoutFunc()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Log out</span></li>
</ul>